'use client'

import { getCookie } from 'cookies-next'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

import useAuth from '@/hooks/useAuth'
import useConversations from '@/hooks/useConversations'

import ChatLayout from '@/components/Admin/Conversations/ChatLayout'
import Chatbot from '@/components/Chatbot'

import { switchLanguage } from '@/i18n'

const Home = () => {
  // Set language from cookie
  const lang = getCookie('locale')
  if (lang) {
    setTimeout(() => switchLanguage(lang))
  }

  const { user, error, isLoading } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const { mutate, conversations, setSize, noMoreData } = useConversations(
    30,
    user?.email
  )

  useEffect(() => {
    if (error && error.response) {
      router.replace(
        `/login${pathname ? '?redirect=' + encodeURIComponent(pathname) : ''}`
      )
    }
  }, [error])

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <ChatLayout
          conversations={conversations}
          mutateConversations={mutate}
          setConversationsSize={setSize}
          noMoreData={noMoreData}
        >
          <Chatbot mutateConversations={mutate} indexPage />
        </ChatLayout>
      )}
    </>
  )
}

export default Home
